import { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, Text, Platform } from 'react-native'
import { FastField, Field, Formik } from 'formik'

import { cuitValidator, validateEmail } from '@utils/validation'
import Input from '@components/common/Input'
import Select from '@components/common/Select'
import ButtonCustom from '@components/common/ButtonCustom'
import useNetwork from '@utils/network'
import SnackbarCustom from '@components/common/SnackbarCustom'

import { CropContext } from '@contextState/crop'
import { useOfflineCrops } from '../offline/queries/crops'
import { QUEUE_TYPES } from '../offline/queries/queue'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'
import { ROLES, VALIDATE_ROL_CUIT } from '@constants/roles'
import { useSelector } from 'react-redux'
import { identifierName } from '@utils/countries'
import {
  notSpecialCharacters,
  onlyNumbers,
} from '@common/utils/regularsExpressions'
import { useAuth } from '@common/hooks'
import { useCountries } from '@common/hooks/useCountries'

const isWeb = Platform.OS === 'web'

function CropCollaboratorsAddScreen({ route, navigation }) {
  const { doRequest, loading } = useNetwork()
  const { storeOfflineCollaborator } = useOfflineCrops()

  const { fetchCrop } = useContext(CropContext)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { user, config } = useAuth()
  const { roleTypes } = useSelector((state) => state.rolesReducer)
  const { t } = useContext(LanguageContext)
  const { countries } = useSelector((state) => state.countriesReducer)
  const { allCountryData } = useCountries()
  const [message, setMessage] = useState('')
  const [visible, setVisible] = useState(false)
  const [countriesData, setCountriesData] = useState([])
  const [roles, setRoles] = useState([])
  const [userCrop, setUserCrop] = useState({})
  const [identifierCompany, setIdentifierCompany] = useState('')

  const collaboratorsRedux = useSelector(
    (state) => state.collaborators.collaborators
  )
  /**
   * GET CROP
   */
  useEffect(() => {
    if (collaboratorsRedux.length) {
      const me = collaboratorsRedux.find(
        (member) => member.userId._id === user._id
      )
      setUserCrop({
        ...me,
        type: me.rol,
      })

      const rol = roleTypes.find((role) => role.value === me.rol)
      if (rol) {
        setRoles(
          rol.assignable.map((item) => ({ ...item, label: item.keyLabel }))
        )
      }
    } else {
      getCrop()
    }
  }, [])

  /**
   * GET IDENTIFIER COMPANY
   */
  useEffect(() => {
    if (VALIDATE_ROL_CUIT[userCrop.type]) {
      setIdentifierCompany(userCrop.identifier)
    }
  }, [userCrop])

  const getCrop = async () => {
    const response = await fetchCrop(route.params.id)

    if (response) {
      const me = response.members.find((member) => member.user._id === user._id)

      setUserCrop(me)
      setRoles(
        ROLES[me.type].map((value) => ({ ...value, label: value.label(t) }))
      )
    }
  }

  /**
   * UPDATE COUNTRY STATE
   */
  useEffect(() => {
    if (!countries.length) {
      return
    }
    setCountriesData(
      countries.map((element) => ({
        label: element.name,
        value: element._id,
      }))
    )
  }, [countries])

  async function handleSubmit(values) {
    values = {
      ...values,
      identifier: values.identifier.trim(),
    }
    const url = `crops/${route.params.id}/collaborators`
    const method = 'POST'
    const existsCollaborator = collaboratorsRedux.find(
      (collaborator) =>
        collaborator?.email === values.email &&
        collaborator?.identifier === values.identifier
    )

    if (existsCollaborator) {
      setMessage(t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_1)
      setVisible(true)
    } else if (validateCompanyRol(values.identifier)) {
      setMessage(t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_5)
      setVisible(true)
    } else {
      if (isConnected) {
        await doRequest({
          method,
          url,
          data: values,
        })
      } else {
        await storeOfflineCollaborator(
          route.params.id,
          values,
          { url, method },
          QUEUE_TYPES.CREATE_COLLABORATOR
        )
      }

      setMessage(t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_2)
      setVisible(true)

      navigation.goBack()
    }
  }

  /**
   *
   * @param {*} value
   * @returns VALIDATE COUNTRY SELECTED
   */
  const validateCompany = (value) => {
    let error
    if (!value) {
      error = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_3
    } else if (value && isNaN(Number(value))) {
      error = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_4
    } else if (!cuitValidator.test(value)) {
      error = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_5
    } else if (/\s/g.test(value)) {
      error = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_6
    } else if (!value.match(onlyNumbers)) {
      error = t('ERRORS.FORM_ERRORS.IS_NUMBER')
    }
    return error
  }

  function validate(values) {
    const errors = {}
    const nameCountry = countriesData.find(
      (element) => element.value === values.country
    )?.label

    if (!values.country) {
      errors.country = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_3
    }

    if (
      nameCountry &&
      nameCountry === 'Argentina' &&
      validateCompany(values.identifier)
    ) {
      errors.identifier = validateCompany(values.identifier)
    }

    if (!values.email) {
      errors.email = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_3
    } else if (!validateEmail(values.email)) {
      errors.email = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_8
    }

    if (!values.type) {
      errors.type = t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_3
    }

    if (validateCompanyRol(values.identifier)) {
      errors.identifier = validateCompanyRol(values.identifier)
    }

    if (nameCountry && nameCountry !== 'Argentina') {
      if (
        values?.identifier &&
        !values.identifier.match(notSpecialCharacters)
      ) {
        errors.identifier = t('ERRORS.FORM_ERRORS.NOT_SPECIAL_CHARACTERS')
      }
    }

    return errors
  }

  /**
   *
   * @param {*} values
   * @returns Input Company
   */
  const renderInputIdentifier = (country) => {
    const nameCountry = country?.name

    if (nameCountry === 'Argentina') {
      return (
        <FastField
          component={Input}
          name='identifier'
          inputProps={{
            label: identifierName(allCountryData(country?._id)?.alpha3Code, t),
            disabled: identifierCompany,
          }}
          helpText={t('VIEWS').CROP_COLLABORATOR_ADD.FIELD_1.HELPTEXT}
        />
      )
    } else {
      return (
        <FastField
          component={Input}
          name='identifier'
          inputProps={{
            label: identifierName(allCountryData(country?._id)?.alpha3Code, t),
            disabled: identifierCompany,
          }}
          helpText={t('VIEWS').CROP_COLLABORATOR_ADD.FIELD_2.HELPTEXT}
        />
      )
    }
  }

  const helpTextRol = (value) =>
    value === 'PRODUCER' ? (
      <Text>{t('VIEWS').CROP_COLLABORATOR_ADD.FIELD_5.HELPTEXT}</Text>
    ) : null

  const styleButton = (isValid) =>
    !isValid || loading ? styles.buttonDisabled : styles.button

  const styleLabelButton = (isValid) =>
    !isValid || loading ? styles.textButtonDisabled : styles.textButton

  const validateCompanyRol = (identifier) => {
    let error
    if (VALIDATE_ROL_CUIT[userCrop.type]) {
      error =
        userCrop.identifier !== identifier
          ? t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_5
          : null
    }
    return error
  }

  return (
    <View style={styles.container}>
      <Formik
        initialValues={{
          email: '',
          type: '',
          identifier: identifierCompany,
          country: '',
        }}
        validate={validate}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isValid, values, handleSubmit }) => {
          return (
            <>
              <View style={{ flex: 1 }}>
                <>
                  <Field
                    component={Select}
                    name='country'
                    label={t('VIEWS').CROP_COLLABORATOR_ADD.FIELD_3.LABEL}
                    options={countriesData}
                  />

                  {renderInputIdentifier(config?.companySelected?.country)}

                  <FastField
                    name='email'
                    component={Input}
                    inputProps={{
                      label: t('VIEWS').CROP_COLLABORATOR_ADD.FIELD_4.LABEL,
                      autoCapitalize: 'none',
                      [isWeb ? 'autocompletetype' : 'autoCompleteType']:
                        'email',
                      textContentType: 'emailAddress',
                    }}
                  />

                  <Field
                    component={Select}
                    name='type'
                    label={t('VIEWS').CROP_COLLABORATOR_ADD.FIELD_5.LABEL}
                    helpText={helpTextRol(values.type)}
                    options={roles}
                  />
                </>
              </View>
              <ButtonCustom
                disabled={!isValid || loading}
                isLoading={loading}
                onPress={handleSubmit}
                styles={styleButton(isValid)}
                labelStyle={styleLabelButton(isValid)}
              >
                {t('VIEWS').CROP_COLLABORATOR_ADD.TEXT_7}
              </ButtonCustom>
              <SnackbarCustom
                visible={visible}
                onToggle={() => setVisible(!visible)}
              >
                <Text>{message}</Text>
              </SnackbarCustom>
            </>
          )
        }}
      </Formik>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  buttonDisabled: { backgroundColor: '#81C784' },
  button: { backgroundColor: '#4CAF50' },
  textButtonDisabled: { color: 'rgba(255, 255, 255, .5)' },
  textButton: { color: 'white' },
})

export default CropCollaboratorsAddScreen
