import { Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { makeRequest } from '@common/services'

import { GlobalStateInterface } from '@store/interfaces'
import { setRoles } from '@store/slices/roles.slice'
import { useOfflineTypes } from '@offline/queries/types'

export const useRoles = () => {
  const dispatch = useDispatch()
  const { isConnected } = useSelector(
    (state: GlobalStateInterface) => state.connectionReducer
  )

  const { storeType, getTypes } = useOfflineTypes()

  const fetchRoles = async () => {
    let data = []
    if (isConnected) {
      const response = await makeRequest({
        url: 'commons/roles',
      })

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('roletypes', data)
      }
    } else {
      data = await getTypes('roletypes')
    }

    const roles = data.map((role: any) => ({
      isInactive: role.isInactive,
      label: role.keyLabel,
      value: role.value,
      assignable: role.assignable ?? [],
      canMarkFlags: role.canMarkFlags ?? [],
    }))

    dispatch(setRoles(roles))
  }

  return {
    fetchRoles,
  }
}
